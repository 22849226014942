const buttonCallsData = [
  {
    id: 1,
    message: "Are you already a ",
    boldMessage: "Patreon Subscriber?",
    buttonLink: "https://patreon.com/login",
    buttonMessage: "Log in with Patreon and redeem your games!",
  },
  {
    id: 2,
    message: "Don't feel like it, don't worry about it, the games are on ",
    boldMessage: "itch.io!",
    buttonLink: "https://itch.io/",
    buttonMessage: "Log in to Itch.io and see my enhanced games",
  },
];

export default buttonCallsData;
