import React from 'react'
import Button from './Button'

const ButtonCall = ({message, boldMessage, buttonLink, buttonMessage}) => {

    const lightTheme = 'font-description text-textColor'
    const darkTheme = 'darK:font-descriptionEnhanced dark:text-textColorDark'
        
    return (
        <div className={`${lightTheme} ${darkTheme} lg:ml-10 py-12 text-2xl`}>
            <p className='text-center lg:text-left'>
                {message}<b>{boldMessage}</b>
            </p>
            <Button onClick={buttonLink} buttonMessage={buttonMessage}/>
        </div>
    )
}

export default ButtonCall