import React from 'react'

const LinkComponent = ({link, text}) => {

    const lightTheme = `m-5 text-2xl`
    const darkTheme = `m-5 text-2xl`

    const linkLightTheme = `font-description text-textColor hover:text-textColorDark`
    const linkDarkTheme = `dark:font-descriptionEnhanced dark:text-textColorDark dark:hover:text-textColor`
    return (
        <div className={`${lightTheme} ${darkTheme} m-5 text-2xl transition-all hover:scale-105 max-w-fit`}>
            <a
            className={`${linkLightTheme} ${linkDarkTheme} underline underline-offset-8  font-bold transition-all`}
            href={link} 
            target="_blank" 
            rel="noopener noreferrer">
                {text}
            </a>
        </div>
    )
}

export default LinkComponent