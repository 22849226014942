import { FaYoutube } from "react-icons/fa";
import { FaItchIo } from "react-icons/fa";
import { FaSteam } from "react-icons/fa";

const socialMedia = "h-10 w-10 m-2 pointer";

const gameCardData = [
  {
    id: 1,
    title: "Punkcake",
    image: "https://via.placeholder.com/150",
    description:
      "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
    modalDetails: {
      title: "Punkcake",
      descriptionText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      featureText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      images: [
        {
          id: 1,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 2,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 3,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 4,
          image: "https://via.placeholder.com/150",
        },
      ],
      articleList: [
        {
          id: 1,
          title: "Punkcake article 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake article 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 3,
          title: "Punkcake article 3",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      additionalLinks: [
        {
          id: 1,
          title: "Punkcake link 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake link 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      teamList: [
        {
          id: 1,
          title: "Michael Taylor",
          link: "",
        },
      ],
    },
    links: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        icon: <FaYoutube className={socialMedia} />,
        labelText: "Trailer",
      },
      {
        id: 2,
        link: "https://punkcake.itch.io/",
        icon: <FaItchIo className={socialMedia} />,
        labelText: "Itch Page",
      },
      {
        id: 3,
        link: "https://store.steampowered.com/app/1564480/Punkcake/",
        icon: <FaSteam className={socialMedia} />,
        labelText: "Steam Page",
      },
    ],
  },
  {
    id: 2,
    title: "Punkcake",
    image: "https://via.placeholder.com/150",
    description:
      "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
    modalDetails: {
      title: "Punkcake",
      descriptionText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      featureText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      images: [
        {
          id: 1,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 2,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 3,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 4,
          image: "https://via.placeholder.com/150",
        },
      ],
      articleList: [
        {
          id: 1,
          title: "Punkcake article 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake article 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 3,
          title: "Punkcake article 3",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      additionalLinks: [
        {
          id: 1,
          title: "Punkcake link 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake link 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      teamList: [
        {
          id: 1,
          title: "Michael Taylor",
          link: "",
        },
      ],
    },
    links: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        icon: <FaYoutube className={socialMedia} />,
        labelText: "Trailer",
      },
      {
        id: 2,
        link: "https://punkcake.itch.io/",
        icon: <FaItchIo className={socialMedia} />,
        labelText: "Itch Page",
      },
      {
        id: 3,
        link: "https://store.steampowered.com/app/1564480/Punkcake/",
        icon: <FaSteam className={socialMedia} />,
        labelText: "Steam Page",
      },
    ],
  },
  {
    id: 3,
    title: "Punkcake",
    image: "https://via.placeholder.com/150",
    description:
      "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
    modalDetails: {
      title: "Punkcake",
      descriptionText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      featureText:
        "Punkcake is a 2D platformer game where you play as a cupcake with a mohawk. You must fight your way through the city to defeat the evil cake corporation.",
      images: [
        {
          id: 1,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 2,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 3,
          image: "https://via.placeholder.com/150",
        },
        {
          id: 4,
          image: "https://via.placeholder.com/150",
        },
      ],
      articleList: [
        {
          id: 1,
          title: "Punkcake article 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake article 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 3,
          title: "Punkcake article 3",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      additionalLinks: [
        {
          id: 1,
          title: "Punkcake link 1",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
        {
          id: 2,
          title: "Punkcake link 2",
          link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        },
      ],
      teamList: [
        {
          id: 1,
          title: "Michael Taylor",
          link: "",
        },
      ],
    },
    links: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=3b4j4X4j0qE",
        icon: <FaYoutube className={socialMedia} />,
        labelText: "Trailer",
      },
      {
        id: 2,
        link: "https://punkcake.itch.io/",
        icon: <FaItchIo className={socialMedia} />,
        labelText: "Itch Page",
      },
      {
        id: 3,
        link: "https://store.steampowered.com/app/1564480/Punkcake/",
        icon: <FaSteam className={socialMedia} />,
        labelText: "Steam Page",
      },
    ],
  },
];

export default gameCardData;
