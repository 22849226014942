import React from 'react'
import {useState, useEffect} from 'react';

const dark = "dark"
const light = "light"

const DarkModeToggle = () => {
  const savedTheme = localStorage.getItem('theme');

  const [theme, setTheme] = useState(savedTheme || 
    (window.matchMedia(`(prefers-color-scheme: ${dark})`).matches 
    ? dark : light));

  useEffect(() => {

    document.documentElement.classList.toggle(dark, theme === dark);

    localStorage.setItem('theme', theme);
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme((prevTheme) => (prevTheme === dark ? light : dark));
  };
  const lightTheme = `bg-foreground font-description text-textColo 
  hover:bg-foregroundDark hover:text-textColorDark`
  const darkTheme = `dark:bg-foregroundDark dark:font-descriptionEnhanced 
  dark:text-textColorDark dark:hover:bg-foreground dark:hover:text-textColor`

  return (
    <button 
      className={`${lightTheme} ${darkTheme} 
      mt-6 mr-6 font-bold py-2 px-4 rounded transition-all hover:scale-x-105`} 
      onClick={handleThemeSwitch}>
        {theme === dark ? 'Normal Mode' : '+Enhanced Mode'}
    </button>
  )
}

export default DarkModeToggle