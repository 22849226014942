import React from 'react';
import InfoPage from './components/Pages/InfoPage';
import GameGrid from './components/Pages/GameGrid';
import Footer from './components/Pages/Footer';
import NavBar from './components/Pages/NavBar';

const App = () => {

  const lightTheme = 'bg-background'
  const darkTheme = 'dark:bg-backgroundDark'

  return(
    <div className= {`${lightTheme} ${darkTheme} transition-all`}>
      <NavBar/>
      <InfoPage />
      <GameGrid/>
      <Footer/>
    </div>
  )
}

export default App;
