import React from 'react'

const TextComponent = ({text}) => {

    const lightTheme = 'font-description text-textColor'
    const darkTheme = 'dark:font-descriptionEnhanced dark:text-textColorDark'
        
    return (
        <div className={`${lightTheme} ${darkTheme}  m-5 text-2xl`}>
            <p>{text} Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia, error expedita totam quis iure inventore facilis nostrum aspernatur sequi illo rerum optio similique repellendus, blanditiis iste dolore. Totam, soluta odit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam consequuntur, incidunt numquam sit iusto harum ea debitis totam accusamus ut porro iure alias nostrum beatae consequatur voluptas. Voluptates, optio cupiditate! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quo sunt aliquid, vel quod dolor reiciendis repudiandae enim odit ex tempore eaque rem laborum quisquam ipsam quaerat maxime, adipisci et!</p>
        </div>
    )
}

export default TextComponent