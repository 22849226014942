import React from "react";

const Card = (props) => {

  const lightTheme = `bg-foreground`
  const darkTheme = `dark:bg-foregroundDark `

  return (
    <div
      className={`${props.className} ${lightTheme} ${darkTheme} transition w-11/12 rounded-3xl mx-auto my-10 shadow-2xl`}
    >
      {props.children}
    </div>
  );
};

export default Card;