import React from 'react'

const Button = ({className, onClick, buttonMessage}) => {

    const lightTheme ='bg-textColor font-descriptionEnhanced text-textColorDark'
    const darkTheme =`dark:bg-backgroundDark dark:font-descriptionEnhanced 
    dark:text-textColorDark dark:font-bold
    dark:rounded-3xl`

    return (
        <button className={`${className} ${lightTheme} ${darkTheme} m-5 p-5 text-3xl 
        transition-all ease-in-out shadow-[0_10px_var(--button-shadow)] 
        hover:scale-105`} 
        onClick={onClick}>
            {buttonMessage}
        </button>
    )
}

export default Button