const contactListData = [
  {
    id: 1,
    title: "Company Email",
    link: "mailto:michael.taylor.gamedev@gmail.com",
  },
  {
    id: 2,
    title: "Press Requests",
    link: "mailto:michael.taylor.gamedev@gmail.com",
  },
  {
    id: 3,
    title: "Twitter",
    link: "https://twitter.com/",
  },
  {
    id: 4,
    title: "Email List",
    link: "https://mailchi.mp/",
  },
];

export default contactListData;
