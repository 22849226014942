import React from 'react'
import ReactDom from 'react-dom'
import ReactPlayer from 'react-player'
import Button from '../Shared/UI Elements/Button'
import ModalDescription from '../Shared/UI Elements/ModalDescription'
import ModalImages from '../Shared/UI Elements/ModalImages'
import ModalLinkedList from '../Shared/UI Elements/ModalLinkedList'

import { IoMdCloseCircleOutline } from "react-icons/io";

import contactListData from '../data/contactList'

const OVERLAY_STYLE = `fixed top-0 left-0 right-0 bottom-0 transition-all z-10`

const MODAL_STYLE = `fixed [width:90%] h-5/6 [--tw-translate-x:5.5%] 
translate-y-9 px-10 z-10 overflow-auto border-8 transition-all`


const overlayLightTheme = '[background-color:var(--light-backgroundFade)]'
const overlayDarkTheme = '[background-color:var(--dark-backgroundFade)]'

const modalLightTheme = 'bg-foreground'
const modalDarkTheme = 'dark:bg-foregroundDark rounded-xl'

const titleLightTheme = 'text-textColor font-title font-bold text-2xl my-3'

const GameModal = ({open, gameDetails, onClose}) => {

    if (!open) return null
    
    return ReactDom.createPortal (
        <div className={`${OVERLAY_STYLE} ${overlayLightTheme} ${overlayDarkTheme}`}>
            <div className={`${MODAL_STYLE} ${modalLightTheme} ${modalDarkTheme}`}>
                <div className='flex flex-col'>
                    <h1 className={`${titleLightTheme} text-center`}>{gameDetails.title}</h1>
                    <IoMdCloseCircleOutline className='absolute top-5 right-0 [width:10%] [height:10%] max-h-xs max-w-xs cursor-pointer hover:text-background dark:hover:text-textColorDark' onClick={onClose}/>
                    <div className='mx-auto'>
                        <ReactPlayer width="33.33vw" height="40.33vh" url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
                    </div>
                    <ModalDescription title="Description" description={`${gameDetails.descriptionText}`}/>
                    <ModalDescription title="Features" description={`${gameDetails.featureText}`}/>
                    <ModalImages images={gameDetails.images}/>
                    <ModalLinkedList
                        className={'my-8'}
                        title="Selected Articles"
                        items={gameDetails.articleList}/>
                    <ModalLinkedList
                    className={'my-8'}
                        title="Additional Links"
                        items={gameDetails.additionalLinks}/>
                    <div className='mb-32'>
                        <ModalLinkedList className={'absolute [left:10%]'}
                        title="Team"
                        items={gameDetails.teamList}/>
                        <ModalLinkedList className={'absolute [right:10%]'}
                        title="Contact"
                        items={contactListData}/>
                    </div>
                    <Button onClick={onClose} buttonMessage={`Close`}></Button>
                </div>
                
            </div>
        </div>,
        document.getElementById('portal')
  )
}

export default GameModal