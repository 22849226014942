import React from 'react'
import FontAwesomeButton from '../Shared/UI Elements/FontAwesomeButton';

import footerLinksData from "../data/footerLinks";

const footerIcons = footerLinksData.map((link) =>
  <FontAwesomeButton
  key={link.id} 
  link ={link.link} 
  icon = {link.icon} 
  labelText={link.labelText}/>)

const Footer = () => {

  const lightTheme = 'bg-foreground font-description font-description text-textColor'
  const darkTheme = `dark:bg-foregroundDark dark:font-descriptionEnhanced 
  dark:font-descriptionEnhanced dark:text-textColorDark`

  return (
    <div className={`${lightTheme} ${darkTheme} text-center h-24'`}>
        <div className='flex justify-center'>
             {footerIcons}
        </div>
        <p className='mt-8'>©{new Date().getFullYear()} Enhance Attack</p>
    </div>
  )
}

export default Footer