import React, {useState} from 'react'
import Card from '../Shared/UI Elements/Card'
import GameModal from './GameModal'
import FontAwesomeButton from '../Shared/UI Elements/FontAwesomeButton';
import Button from '../Shared/UI Elements/Button';

const GameCard = ({title, image, description, modalDetails, links}) => {

  const [gameModal, setGameModal] = useState(false);

  const gameLinks = links.map((link) =>
    <FontAwesomeButton 
      key={link.id}
      link={link.link} 
      icon={link.icon} 
      labelText={link.labelText}/>)

  const cardLightTheme = 'bg-foreground rounded-sm text-textColor font-description'
  const cardDarkTheme = `dark:bg-foregroundDark dark:text-textColorDark 
  dark:font-descriptionEnhanced dark:max-w-sm dark:rounded-xl 
  hover:-translate-y-1 hover:scale-105`
  
  const imageLightTheme = 'mx-auto my-3 h-48 w-48 rounded-3xl cursor-pointer'
  const imageDarkTheme = 'mx-auto my-3 h-48 w-48 rounded-3xl cursor-pointer'
  
  const descLightTheme = 'text-textColor font-description'
  const descDarkTheme = 'dark:text-textColorDark dark:font-descriptionEnhanced'

  return (
    <Card className={`${cardLightTheme} ${cardDarkTheme}`} >
      <div className='flex justify-center flex-col'>
        <h1 className='text-center font-bold text-2xl my-3'>{title}</h1>
        <img 
        src={image} 
        alt="Placeholder" 
        className={`${imageLightTheme} ${imageDarkTheme}`}
        onClick={() => setGameModal(true)}/>
        <p className={`${descLightTheme} ${descDarkTheme} mx-10 py-5`}>
          {description}
        </p>
        <Button onClick={() => setGameModal(true)} buttonMessage='More Info'/>
      </div>
        <GameModal open={gameModal} gameDetails={modalDetails} onClose={() => setGameModal(false)}/>
        <div className='flex justify-center py-3 mb-6'>
          {gameLinks}
        </div>
    </Card>
  )
}

export default GameCard