import React from 'react'

const ModalDescription = ({title, description}) => {

    const className = 'mx-16 my-10'
    
    const titleLightTheme = 'text-textColor text-2xl font-bold'
    const titleDarkTheme = 'dark:text-textColorDark dark:text-2xl dark:font-bold'

    const descLightTheme = 'text-textColor text-xl font-description'
    const descDarkTheme = 'dark:text-textColorDark dark:text-xl dark:font-descriptionEnhanced'

    return (
        <div className={`${className}`}>
            <h1 className={`${titleLightTheme} ${titleDarkTheme}`}>{title}</h1>
            <p className={`${descLightTheme} ${descDarkTheme}`}>{description}</p>
        </div>
    )
}

export default ModalDescription