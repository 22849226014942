import React from 'react'
import DarkModeToggle from '../Shared/UI Elements/DarkModeToggle'

const NavBar = () => {
  return (
    <div className='h-9'>
        <div className='float-right'>
            <DarkModeToggle/>
        </div> 
    </div>
  )
}

export default NavBar