const infoLinksData = [
  {
    id: 1,
    link: "https://punkcake.club/",
    text: "Subcribe on Patreon for $3 a month!",
  },
  {
    id: 2,
    link: "https://twitter.com/",
    text: "Follow Me on Twitter",
  },
  {
    id: 3,
    link: "https://mailchimp.com",
    text: "Join my Email list, free game included",
  },
];

export default infoLinksData;
