import React from 'react'

const ModalLinkedList = ({className, title, items}) => {

    const openTab = (url) => {
        window.open(url, '_blank')
    }

    const linkStyle = `hover:underline hover:cursor-pointer`

    const organizedList = items.map((item) =>
        <li key={item.id}
            className={`${item.link !== '' && linkStyle}`} 
            onClick={() => openTab(item.link)}
        >
            {item.title}
        </li>
    )

    const lightTheme = `text-textColor font-description`
    const darkTheme = `dark:text-textColorDark
    dark:font-descriptionEnhanced`

    return (
        <div className={`${className} ${lightTheme} ${darkTheme} 
         w-max text-xl`}>
            <h1 className={`underline underline-offset-4`}>{title}</h1>
            <ul className='list-disc'>
                {organizedList}
            </ul>
        </div>
    )
}

export default ModalLinkedList