import React, {useState} from 'react'
import ElementLabel from './ElementLabel'

const FontAwesomeButton = ({link, icon, labelText}) => {

    const [isHovered, setIsHovered] = useState(false)

    const lightTheme = 'text-textColor hover:text-background'
    const darkTheme = 'dark:text-textColorDark dark:hover:text-textColor'

    return (
        <a 
        href={link} 
        className={`${lightTheme} ${darkTheme} mx-2`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
            {icon}
            {isHovered && <ElementLabel text={labelText}/>}
        </a>
    )
}

export default FontAwesomeButton