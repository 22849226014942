import { MdEmail } from "react-icons/md";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";

const socialMedia = "h-10 w-10 m-2 pointer";

const footerLinksData = [
  {
    id: 1,
    link: "example.com",
    icon: <MdEmail className={socialMedia} />,
    labelText: "Email List",
  },
  {
    id: 2,
    link: "example.com",
    icon: <FaYoutube className={socialMedia} />,
    labelText: "Youtube Channel",
  },
  {
    id: 3,
    link: "example.com",
    icon: <BsTwitterX className={socialMedia} />,
    labelText: "Twitter",
  },
];

export default footerLinksData;
