import React from 'react'

const ModalImages = ({images}) => {

    const organizedImages = images.map((image) =>
        <img key = {image.id} className="mx-auto" src={image.image} alt="Placeholder"/>
    )

  return (
    <div className='grid grid-cols-3 gap-10 my-10'>
        {organizedImages}
    </div>
)
}

export default ModalImages