import React from 'react'
import TextComponent from '../Shared/UI Elements/TextComponent'
import LinkComponent from '../Shared/UI Elements/LinkComponent'
import Card from '../Shared/UI Elements/Card'
import ButtonCall from '../Shared/UI Elements/ButtonCall'

import infoData from '../data/info';
import infoLinksData from '../data/infoLinks'
import buttonCallsData from '../data/buttonCalls'

const InfoPage = () => {
  const infoLinks = infoLinksData.map((infoLink) => 
      <LinkComponent 
      key = {infoLink.id}
      link={infoLink.link} 
      text={infoLink.text}/>)

  const buttonCalls = buttonCallsData.map((buttonCall) =>
    <ButtonCall
      key = {buttonCall.id} 
      message= {buttonCall.message}
      boldMessage= {buttonCall.boldMessage}
      buttonLink={() => window.open(buttonCall.buttonLink, "_blank")}
      buttonMessage={buttonCall.buttonMessage}/>)

  const lightTheme = 'text-textColor font-title'
  const darkTheme = 'dark:text-textColorDark dark:font-titleEnhanced'

  return (
    <Card>
      <h1 className={`${lightTheme} ${darkTheme} 
      text-3xl sm:text-8xl text-center py-10 transition-all duration-500`}>Enhance Attack</h1>
      <TextComponent text={infoData[0].description}/>
      {infoLinks}
      <TextComponent text={infoData[1].description}/>
      {buttonCalls}
    </Card>
  )
}

export default InfoPage