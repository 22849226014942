import React from 'react'

const lightTheme = 'bg-background invisible'
const darkTheme = 'dark:text-textColorDark dark:visible'

const ElementLabel = ({text}) => {
  return (
    <div className={`${lightTheme} ${darkTheme} 
    bg-textColor text-base rounded absolute [z-index:3] px-2 py-1`}>
        <p>{text}</p>
    </div>
  )
}

export default ElementLabel