import React from 'react'
import GameCard from './GameCard'

import gameCardData from '../data/gameCards'

const GameGrid = () => {

  const darkTheme = "grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1"

  const gameCards = gameCardData.map((card) => 
      <GameCard 
        key={card.id}
        title={card.title}
        image={card.image}
        description={card.description}
        modalDetails={card.modalDetails}
        links={card.links}
      /> 
    )

  return (
    <div className={`${darkTheme}`}>
      {gameCards} 
    </div>
  )
}

export default GameGrid