const infoData = [
  {
    id: 1,
    description: `Hello there, I'm 
      making better versions of bad games.`,
  },
  {
    id: 2,
    description: `I'm making better versions of bad games`,
  },
];

export default infoData;
